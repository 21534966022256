import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {Nav} from 'react-bootstrap';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import LocalMoviesOutlinedIcon from '@mui/icons-material/LocalMoviesOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { styled, useTheme } from '@mui/material/styles';
import { instance } from '../../axiosConfig'
import './NavSide.css'

const NavBar =(props)=>{
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme();
    const axiosInstance = require('../../axiosConfig');

    const [open, setOpen] = useState(true);
    const [initialActiveState, setActiveState] = useState({active: false});
    const [vendorRole, setVendorRole] = useState()

    const changeActive=(e)=>{
        setActiveState({...initialActiveState, active: !initialActiveState.active});
    }

    let active_class = initialActiveState.active ? "activeTab" : 'inactiveTab';
    const drawerWidth = 280;

    const logOut = async(e) =>{
        e.preventDefault()
        
        const res = await axiosInstance.instance.get('/logout')
        console.log(res.data)

        if(res.status === 200){
            localStorage.removeItem('userId')
            localStorage.removeItem('vendorName')
            navigate('/')
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const openedFullNav = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
        background: '#2D132C', //Drawer color
        color:'white'
    });
      
    const closedNav = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`, [theme.breakpoints.up('sm')]: {width: `calc(${theme.spacing(8)} + 1px)`,},
        background: '#2D132C', //Drawer color
        color:'white'
    });

    // Custom Drawer using MUI Drawer
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
            ...openedFullNav(theme),
            '& .MuiDrawer-paper': openedFullNav(theme),
            }),
            ...(!open && {
            ...closedNav(theme),
            '& .MuiDrawer-paper': closedNav(theme),
            }),
        }),
    );

    const AppBar = styled(MuiAppBar, {  shouldForwardProp: (prop) => prop !== 'open',})(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const [navActiveColor, setNavActiveColor] = useState('white')
    
    const setActiveNav=(path)=>{
        if(location.pathname === path){
            setNavActiveColor('#C170C1')
        }else{
            setNavActiveColor('white')
        }
    }

    const getUser= async ()=>{
        const userId = localStorage.getItem('userId')
        const res = await instance.get(`/vendor/${userId}`)

        if(res.data.status === 200){
            setVendorRole(res.data.vendor.role)
        }
    }

    useEffect(()=>{
        getUser()
    },[])
    return(
        <>
            <AppBar position="fixed" open={open} sx={{background:'transparent', boxShadow:'none'}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{marginRight: 5, ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon sx={{color:'white'}} /> 
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer variant='permanent' open={open} onClose={handleDrawerClose}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                       {open ? (theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon sx={{color: 'white'}} />) : null}
                    </IconButton>
                </DrawerHeader>

                <Divider />
                {open ? <a href="/"><img className="nav-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/></a> : 
                <a href="/"><img className="nav-closed-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/></a> 
                }
                
                <Box sx={{ width: 280 }} role="presentation">
                    <List sx={{ paddingTop:'5px'}}>
                        <ListItem key={"Upload Content"} sx={{paddingLeft: !open ? '2px' : ''}}>
                            <ListItemButton onClick={()=>navigate(`/console`)}>
                                <ListItemIcon>
                                    <FileUploadOutlinedIcon sx={{ color: navActiveColor }}/>
                                </ListItemIcon>

                                <ListItemText primary={"Upload Content"} sx={{ color: navActiveColor}}>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>

                        <ListItem key={"Account"} sx={{paddingLeft: !open ? '2px' : ''}}>
                            <ListItemButton onClick={()=>navigate(`/account`)}>
                                <ListItemIcon>
                                    <AccountCircleOutlinedIcon  sx={{ color: navActiveColor}}/>
                                </ListItemIcon>

                                <ListItemText primary={"Account"} sx={{ color: navActiveColor}}>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>

                        {
                            vendorRole === 'admin' ?
                                <>
                                    <ListItem key={"Content"} sx={{paddingLeft: !open ? '2px' : ''}}>
                                        <ListItemButton onClick={()=>navigate(`/content/admin`)}>
                                            <ListItemIcon>
                                                <MovieOutlinedIcon sx={{ color: navActiveColor}}/>
                                            </ListItemIcon>

                                            <ListItemText primary={"All Content"} sx={{ color: navActiveColor}}>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>

                                    <ListItem key={"Admin-Content"} sx={{paddingLeft: !open ? '2px' : ''}}>
                                        <ListItemButton onClick={()=>navigate(`/content`)}>
                                            <ListItemIcon>
                                                <LocalMoviesOutlinedIcon sx={{ color: navActiveColor}}/>
                                            </ListItemIcon>

                                            <ListItemText primary={"Admin Owned Content"} sx={{ color: navActiveColor}}>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            : (
                                <ListItem key={"Content"} sx={{paddingLeft: !open ? '2px' : ''}}>
                                    <ListItemButton onClick={()=>navigate(`/content`)}>
                                        <ListItemIcon>
                                            <MovieOutlinedIcon sx={{ color: navActiveColor}}/>
                                        </ListItemIcon>

                                        <ListItemText primary={"Content"} sx={{ color: navActiveColor}}>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                )
                        }

                        <ListItem key={"Activity"} sx={{paddingLeft: !open ? '2px' : ''}}>
                            <ListItemButton onClick={()=>navigate(`/activity`)}>
                                <ListItemIcon>
                                    <QueryStatsOutlinedIcon sx={{ color: navActiveColor}}/>
                                </ListItemIcon>

                                <ListItemText primary={"Activity"} sx={{ color: navActiveColor}}>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>

                        <ListItem key={"Monetization"} sx={{paddingLeft: !open ? '2px' : ''}}>
                            <ListItemButton onClick={()=>navigate(`/monetization`)}>
                                <ListItemIcon>
                                    <PaidOutlinedIcon sx={{ color: navActiveColor}}/>
                                </ListItemIcon>

                                <ListItemText primary={"Monetization"} sx={{ color: navActiveColor}}>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
                <Divider />

                <List sx={{ position:'absolute', bottom:'0px'}}>
                    <ListItem key={"Failing"} sx={{paddingLeft: !open ? '2px' : ''}}>
                        <ListItemButton onClick={()=>navigate(`/failing`)}>
                            <ListItemIcon>
                                <FeedbackOutlinedIcon sx={{ color: navActiveColor}}/>
                            </ListItemIcon>

                            <ListItemText primary={"Failing Entities"} sx={{ color: navActiveColor}}>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={"Help"} sx={{paddingLeft: !open ? '2px' : ''}}>
                        <ListItemButton onClick={()=>navigate(`/help`)}>
                            <ListItemIcon>
                                <HelpOutlineOutlinedIcon sx={{ color: navActiveColor}}/>
                            </ListItemIcon>

                            <ListItemText primary={"Help"} sx={{ color: navActiveColor}}>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={"/"} sx={{paddingLeft: !open ? '2px' : ''}}>
                        <ListItemButton onClick={(e)=> logOut(e)} >
                            <ListItemIcon>
                                <LogoutOutlinedIcon sx={{ color: navActiveColor}}/>
                            </ListItemIcon>

                            <ListItemText primary={"Log out"} sx={{ color: navActiveColor}}>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    )
}


export default NavBar;