import React, { useState , useEffect} from "react";
import NavSide from '../Dashboard/NavSide'
import { instance } from '../../axiosConfig'

const Monetization = () =>{
    const [creatorViews, setCreatorViews] = useState()
    const [quarterViews, setQuarterViews] = useState()
    const [amount, setAmount] = useState({})
    const [fixedAmount, setFixedAmount] = useState(0)
    const [totalViews, setTotal] = useState({ creator:0, platform:0, creatorUniqueViews:0, platformUniqueViews:0})

    const creatorId = localStorage.getItem('userId')

    const getCreatorViews = async()=>{
        const res = await instance.get(`/${creatorId}/metrics/creator/views`)
        if(res.data.status === 200){
            const {data} = res.data
            console.log(res.data)
            setCreatorViews(data)
        }
    }

    const getOverallQuarterViews = async()=>{
        const res = await instance.get(`/quarter/metrics`)
        if(res.data.status === 200){
            const {data} = res.data
            console.log(res.data)
            setQuarterViews(res.data.metrics)
        }
    }

    const getStripeQuarterlyRevenue= async()=>{
        const res = await instance.get(`/stripe/metrics`)
        if(res.data.status == 200 && !res.data.difference){
            //console.log(res.data)
            setAmount(res.data.balance)
        }else{
            console.log(res.data)
            setFixedAmount(res.data.balance)
        }
    }

    function formatNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    useEffect(()=>{
        getCreatorViews()
        getOverallQuarterViews()
        getStripeQuarterlyRevenue()
    },[])

    useEffect(()=>{
        let totalCreatorViews = 0
        let totatCreatorUniqueViews = 0
        if(creatorViews){
            for(let v of Object.values(creatorViews.quarterlyViews)){
                if(v){
                    totalCreatorViews+=v.views
                    totatCreatorUniqueViews+=v.uniqueViews
                }
            }
        }

        let totalPlatformViews = 0
        let totalPlatformUniqueViews = 0
        if(quarterViews){
            for(let v of Object.values(quarterViews)){
                if(v){
                    totalPlatformViews+=v.views
                    totalPlatformUniqueViews+=v.uniqueViews
                }
            }
        }

        setTotal({
            ...totalViews, 
            creator:totalCreatorViews, 
            platform:totalPlatformViews, 
            creatorUniqueViews:totatCreatorUniqueViews, 
            platformUniqueViews:totalPlatformUniqueViews
        })
    },[creatorViews])


    return(
        <div style={{background:'#1F1025'}}>
            <NavSide/>
            <div className="container text-white">
                <h1 className="mt-4">Monetization</h1>
                <div className="row mt-5">
                    {/* <div className="col-md-4">
                        <h4>Revenue</h4>
                        $ {totalViews && ((totalViews.creatorUniqueViews * 2 /totalViews.platformUniqueViews)* fixedAmount).toFixed(2)}
                    </div> */}

                    <div className="col-md-4">
                        <h4>Views</h4>
                        {totalViews && totalViews.creator}
                        {/* <div style={{textSize:'10px'}}>By Load factor: {totalViews && totalViews.creator * 2}</div> */}
                    </div>

                    <div className="col-md-4">
                        <h4>Unique Views</h4>
                        {totalViews && totalViews.creatorUniqueViews}
                        <div style={{textSize:'10px'}}>By Load factor: {totalViews && totalViews.creatorUniqueViews * 2}</div>
                    </div>

                    <div className="col-md-4">
                        <h4>Platform Views</h4>
                        {totalViews && totalViews.platform}
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-6">
                        <div style={{border:'1px solid #C170C1', borderRadius:'5px', background:'#2D132C'}}>
                            <h3 style={{fontWeight:'bold'}} className="my-2">Q1</h3>
                            <hr style={{border:'1px solid #C170C1'}} />
                            <p>Actual Views: {creatorViews && creatorViews.quarterlyViews[1].views}</p>
                            <p>Unique Views: {creatorViews && creatorViews.quarterlyViews[1].uniqueViews}</p>
                            {/* Default LOAD FACTOR at the moment 2. load factor can either be 1 or 2 based on co-production*/}
                            <p>Unique Views by load factor: {creatorViews && creatorViews.quarterlyViews[1].uniqueViews * 2}</p>
                            
                            <p style={{fontWeight:'bold'}}>($) Revenue: 
                                {
                                    creatorViews && !isNaN((creatorViews.quarterlyViews[1].uniqueViews * 2) / quarterViews[1].uniqueViews)
                                    ? formatNumber(
                                        Math.floor(
                                            ((creatorViews.quarterlyViews[1].uniqueViews * 2) / quarterViews[1].uniqueViews) *
                                            (amount[1] ? parseInt(amount[1], 10) : 0)
                                        )
                                    )
                                    : 0
                                }
                            </p>

                            <p>Creator view ratio: {creatorViews && creatorViews.quarterlyViews[1].views} : {quarterViews && quarterViews[1].views}</p>
                            <p>Creator Unique view ratio: {creatorViews && creatorViews.quarterlyViews[1].uniqueViews} : {quarterViews && quarterViews[1].uniqueViews}</p>
                            <small>(creator: platform)</small>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div style={{border:'1px solid #C170C1', borderRadius:'5px', background:'#2D132C'}}>
                            <h3 style={{fontWeight:'bold'}} className="my-2" >Q2</h3>
                            <hr style={{border:'1px solid #C170C1'}} />
                            <p>Actual Views: {creatorViews && creatorViews.quarterlyViews[2].views}</p>
                            <p>Unique Views: {creatorViews && creatorViews.quarterlyViews[2].uniqueViews}</p>
                            <p>Unique Views by load factor: {creatorViews && creatorViews.quarterlyViews[2].uniqueViews * 2}</p>
                            <p style={{fontWeight:'bold'}}>($) Revenue: 
                            {
                                creatorViews && !isNaN((creatorViews.quarterlyViews[2].uniqueViews * 2) / quarterViews[2].uniqueViews)
                                ? formatNumber(
                                    Math.floor(
                                        ((creatorViews.quarterlyViews[2].views * 2) / quarterViews[2].views) *
                                        (amount[2] ? parseInt(amount[2], 10) : 0)
                                    )
                                )
                                : 0
                            }
                            </p>

                            <p>Creator view ratio: {creatorViews && creatorViews.quarterlyViews[2].views} : {quarterViews && quarterViews[2].views}</p>
                            <p>Creator Unique view ratio: {creatorViews && creatorViews.quarterlyViews[2].uniqueViews} : {quarterViews && quarterViews[2].uniqueViews}</p>
                            <small>(creator: platform)</small>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 pb-5">
                    <div className="col-md-6">
                        <div style={{border:'1px solid #C170C1', borderRadius:'5px', background:'#2D132C'}}>
                        <h3 style={{fontWeight:'bold'}} className="my-2">Q3</h3>
                        <hr style={{border:'1px solid #C170C1'}} />
                        <p>Actual Views: {creatorViews && creatorViews.quarterlyViews[3].views}</p>
                        <p>Unique Views: {creatorViews && creatorViews.quarterlyViews[3].uniqueViews}</p>
                        <p>Unique Views by load factor: {creatorViews && creatorViews.quarterlyViews[3].uniqueViews * 2}</p>
                        <p style={{fontWeight:'bold'}}>
                            ($) Revenue: 
                            {
                                creatorViews && !isNaN((creatorViews.quarterlyViews[3].uniqueViews * 2) / quarterViews[3].uniqueViews)
                                ? formatNumber(
                                    Math.floor(
                                        ((creatorViews.quarterlyViews[3].uniqueViews * 2) / quarterViews[3].uniqueViews) *
                                        (amount[3] ? parseInt(amount[3], 10) : 0)
                                    )
                                )
                                : 0
                            }
                        </p>

                        <p>Creator view ratio: {creatorViews && creatorViews.quarterlyViews[3].views} : {quarterViews && quarterViews[3].views}</p>
                        <p>Creator Unique view ratio: {creatorViews && creatorViews.quarterlyViews[3].uniqueViews} : {quarterViews && quarterViews[3].uniqueViews}</p>
                        <small>(creator: platform)</small>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div style={{border:'1px solid #C170C1', borderRadius:'5px', background:'#2D132C'}}>
                            <h3 style={{fontWeight:'bold'}} className="my-2">Q4</h3>
                            <hr style={{border:'1px solid #C170C1'}} />
                            <p>Actual Views: {creatorViews && creatorViews.quarterlyViews[4].views}</p>
                            <p>Unique Views: {creatorViews && creatorViews.quarterlyViews[4].uniqueViews}</p>
                            <p>Unique Views by load factor: {creatorViews && creatorViews.quarterlyViews[4].uniqueViews * 2}</p>
                            <p style={{fontWeight:'bold'}}>
                                ($) Revenue: 
                                {
                                creatorViews && !isNaN((creatorViews.quarterlyViews[4].uniqueViews * 2) / quarterViews[4].uniqueViews)
                                ? formatNumber(
                                    Math.floor(
                                        ((creatorViews.quarterlyViews[4].uniqueViews * 2) / quarterViews[4].uniqueViews) *
                                        (amount[4] ? parseInt(amount[4], 10) : 0)
                                    )
                                )
                                : 0
                            }
                            </p>

                            <p>Creator view ratio: {creatorViews && creatorViews.quarterlyViews[4].views} : {quarterViews && quarterViews[4].views}</p>
                            <p>Creator Unique view ratio: {creatorViews && creatorViews.quarterlyViews[4].uniqueViews} : {quarterViews && quarterViews[4].uniqueViews}</p>
                            <small>(creator: platform)</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Monetization;