import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import './Navbar.css'


const NavBar=()=>{
	useEffect(()=>{

	},[])

	return(
		<nav className="navbar navbar-expand-lg navbar-light bg-transparent">
			<a className="navbar-brand" href="/"><img className="navbarbrand-img" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619453419/AfroWatch/Afrowatch_Horizonal_Logo_-_White_cc4u6z.svg" alt=""/></a>
            
			<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"><MenuIcon sx={{color:'white'}}/></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav ms-auto">
					{/* <li className="nav-item mx-3 my-2 my-md-0">
						<Link id='independent-link' className="nav-link px-3" to="/independent">Independent</Link>
					</li> */}

                    <li className="nav-item mx-3 my-2 my-md-0">
						<Link style={{color:'white', border:'1px solid white'}} className="nav-link px-3" to="/login">Studio</Link>
					</li>
				</ul>
			</div>
		</nav>
	)
}

export default NavBar;