import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';

// size={15}
// sx={{
//     color: green[500],
//     zIndex: 1,
//     }}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                sx={{
                color: green[500],
                zIndex: 1,
            }} variant="determinate" {...props} />

            <Box
                sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}
            >
                <Typography
                variant="caption"
                component="div"
                sx={{ color: 'text.secondary' }}
                >
                {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}
  
CircularProgressWithLabel.propTypes = {
/**
 * The value of the progress indicator for the determinate variant.
 * Value between 0 and 100.
 * @default 0
 */
value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;